import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuthToken } from './auth';

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    const token = getAuthToken();
    setAuthToken(token);
    setIsLoading(false);
  }, []);

  const contextValue = { isLoading, authToken };

  return (
    <LoaderContext.Provider value={contextValue}>
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  const context = useContext(LoaderContext);

  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }

  return context;
};
