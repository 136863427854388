import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalLoadingFallback } from "./../components/GlobalLoadingFallback";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      localStorage.removeItem("Token");
      navigate("/login");
    };

    handleLogout();
  }, [navigate]);

  return <> {GlobalLoadingFallback}</>; // or you can render a message or a spinner while logging out
}

export default Logout;
