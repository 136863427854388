import React from "react";
import { fulllogo } from "./imagepath";
import "../assets/css/loading.css";

export const GlobalLoadingFallback = () => {
  return (
    <div className="containers">
      <img className="logos" src={fulllogo} alt="Logo" />
      <div className="loaders"></div>
      <h1 className="texts">Loading...</h1>
    </div>
  );
};

export default GlobalLoadingFallback;
