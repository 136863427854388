import { createContext, useReducer } from "react";
import { login, getUser } from "../util/http";

export const AuthContext = createContext({
  onLogout: () => {},
  onLogin: (formData) => {},
  user: { user: [], isLoggedIn: false },
});

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { user: action.payload, isLoggedIn: true };
    case "LOGIN_FAILURE":
      return { user: [], isLoggedIn: false };
    case "LOGOUT":
      return { user: [], isLoggedIn: false };
    default:
      return state;
  }
}

export function AuthContextProvider({ children }) {
  const [userState, userDispatch] = useReducer(userReducer, {
    user: [],
    isLoggedIn: false,
  });

  const loginHandler = async (formData) => {
    try {
      const token = await login(formData);
      localStorage.setItem("Token", token);   
      const fetchedUser = await getUser();
      userDispatch({ type: "LOGIN_SUCCESS", payload: fetchedUser });
    } catch (error) {
      userDispatch({ type: "LOGIN_FAILURE" });
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem("Token");
    userDispatch({ type: "LOGOUT" });
  };

  const ctxValue = {
    onLogin: loginHandler,
    onLogout: logoutHandler,
    user: userState,
  };

  return (
    <AuthContext.Provider value={ctxValue}>{children}</AuthContext.Provider>
  );
}
